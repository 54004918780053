import { useRef, useEffect } from 'react';
import { useUpdateEffect } from '~shared/hooks/useUpdateEffect';
import { useClientInactivityConfig } from '~SpectateStore/hooks/clientStateHooks';
import { onClientStateChanged } from '~spa/Utils/WrapperIntegration/Ucf';
import { useClientVersion } from './useClientVersion';

export const useClientInactivity = (isUiMode, setIsUiMode, stateInitIsSuccess) => {
    const uiModeHasChanged = useRef(false);
    const timeoutId = useRef(null);
    const isUiModeRef = useRef(isUiMode);
    const { inactivityTimeout, enabled } = useClientInactivityConfig();
    const isInitAndEnabled = stateInitIsSuccess && enabled;

    const {
        checkPending,
        newClientVersion,
        refetch,
    } = useClientVersion(false);

    const handleClientInactivity = () => {
        uiModeHasChanged.current = true;
        setIsUiMode(false);
    };

    const startTimer = () => {
        timeoutId.current = setTimeout(handleClientInactivity, inactivityTimeout * 1000);
    };

    const clearTimer = () => {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
    };

    const handleClientStateChange = ({ clientStateName }) => {
        clearTimer();

        const clientState = clientStateName.toLowerCase();

        if (isUiModeRef.current && clientState === 'background') {
            startTimer();
        } else if (clientState === 'foreground' && uiModeHasChanged.current) {
            refetch();
        }
    };

    const setIsUiModeRef = () => {
        isUiModeRef.current = isUiMode;
    };

    useEffect(() => {
        if (isInitAndEnabled) {
            onClientStateChanged(handleClientStateChange);
            setIsUiModeRef();
        }
    }, [isInitAndEnabled]);

    useEffect(() => {
        if (isInitAndEnabled) {
            setIsUiModeRef();
        }
    }, [isUiMode]);

    useUpdateEffect(() => {
        if (!checkPending && uiModeHasChanged.current) {
            if (newClientVersion) {
                window.location.reload();

                return;
            }

            uiModeHasChanged.current = false;
            setIsUiMode(true);
        }
    }, [checkPending]);
};
