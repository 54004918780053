import {
    createSlice,
} from '@reduxjs/toolkit';

import { GA_COMPONENT_UNKNOWN_COMPONENT_VALUE } from '~shared/utils/google-analytics';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    clearFreebets,
} from '~Betslip/utils/freeBetsActions';

const initialState = [];

const initialSelectionData = {
    stake: 0,
    each_way_selected: false,
    free_bet_id: null,
    sp_selected: false,
    is_scorecast: false,
    is_cast_market: false,
    is_any: false,
    survey_id: null,
    recommended: false,
    recommendation_location: '',
    original_price: undefined,
    bet_locate: GA_COMPONENT_UNKNOWN_COMPONENT_VALUE,
};

const findSelectionInState = (userSelections, selectionId) => {
    return userSelections.find(item => String(item.id) === String(selectionId));
};

const userSelections = createSlice({
    name: 'betslip/userSelections',
    initialState,
    reducers: {
        addSelection: {
            reducer: (state, action) => {
                const selection = findSelectionInState(state, action.payload.id);
                if (selection) {
                    return;
                }
                state.push(action.payload);
            },
            prepare: (selection) => {
                const payload = {
                    ...initialSelectionData,
                    ...selection,
                };

                return {
                    payload,
                };
            },
        },
        addSelections: (state, action) => {
            const {
                selectionIds,
                surveyId,
            } = action.payload;

            const selectionData = selectionIds.map(selectionId => ({
                ...initialSelectionData,
                id: selectionId,
                survey_id: surveyId,
            }));

            return [
                ...state,
                ...selectionData,
            ];
        },
        updateSelection: (state, action) => {
            const {
                id,
                ...rest
            } = action.payload;
            const selection = findSelectionInState(state, id);
            if (!selection) {
                return;
            }

            Object.assign(selection, rest);
        },
        updateAllSelections: (state, action) => {
            state.map(
                selection => Object.assign(selection, action.payload)
            );
        },
        clearSelections: () => initialState,
        removeSelection: (state, action) => {
            return state.filter(
                selection => selection.id !== action.payload
            );
        },
    },
    extraReducers: {
        [refreshBetslip.fulfilled]: (state, action) => {
            const {
                payload: {
                    selection_details: selectionDetails,
                    invalidUserSelections,
                },
            } = action;

            return state
                .filter(
                    ({ id }) => !invalidUserSelections.includes(id)
                )
                .map((userSelection) => {
                    const { id, original_price } = userSelection;

                    if (original_price !== undefined) {
                        return userSelection;
                    }

                    if (!selectionDetails[id]) {
                        return {
                            ...userSelection,
                        };
                    }

                    const {
                        sp_only,
                        decimal_price,
                    } = selectionDetails[id];

                    const sp_selected = sp_only || decimal_price === null;
                    const onAddToBetslipData = {
                        sp_selected,
                        original_price: decimal_price,
                    };

                    return {
                        ...userSelection,
                        ...onAddToBetslipData,
                    };
                });
        },
        [clearFreebets]: (state) => {
            return state.map(userSelection => ({
                ...userSelection,
                free_bet_id: null,
            }));
        },
    },
});

export const {
    addSelection,
    addSelections,
    clearSelections,
    updateSelection,
    removeSelection,
    updateAllSelections,
} = userSelections.actions;

export default userSelections.reducer;
