/*global SPA_CONTAINER_ID,CLIENT_VERSION*/
import { removeConsecutiveSlashes } from '~shared/utils/string';
import {
    getAssetsDomain,
    getWebApiDomain,
} from '~spa/SpectateConfig';

const WEB_API_SEGMENT = '/spectate/';

const buildUrl = (path, isStaticAsset = false) => {
    const baseUrl = isStaticAsset ? getAssetsDomain() : getWebApiDomain();
    let urlPath = path;

    if (!isStaticAsset) {
        urlPath = `${WEB_API_SEGMENT}/${urlPath}`;
    }
    urlPath = removeConsecutiveSlashes(urlPath);

    const url = new URL(urlPath, baseUrl).toString();

    return url;
};

/**
 * Get the static asset full url
 * @param {String} path Static asset path
 * @return {String} the site's environment
 */
export const buildSpectateStaticAssetUrl = path => buildUrl(path, true);

/**
 * Get the PHP (Spectate) endpoint full url
 * @param {String} path PHP endpoint url
 * @return {String} the site's environment
 */
export const buildSpectateUrl = path => buildUrl(path, false);

/**
 * Get the SPA container ID.
 * It's the id of the element in which SPA is rendered.
 *
 * IMPORTANT!
 * The container id is defined in webpack.constants.js file.
 *
 * @return {String} SPA Container ID
 */
export const getSpaContainerId = () => SPA_CONTAINER_ID;

/**
 * Get the current build version
 * @returns {String} Spectate Client Build version
 */
export const getClientVersion = () => CLIENT_VERSION;
