import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectUserSelections,
    selectCountUserSelections,
    selectIsParlayTabAvailable,
} from './userSelectionsSelectors';

import {
    selectIsMultiplesTabAvailable,
} from '~Betslip/selectors/userMultiplesSelectors';

import {
    selectContainerSize,
} from '~SpectateStore/selectors/clientStateSelectors';

import {
    SIZE_SMALL,
    SIZE_LARGE,
} from '~spa/Utils/ContainerSize';

const containerBetslipMapping = {
    [SIZE_SMALL]: 'baby',
    [SIZE_LARGE]: 'full',
};

export const selectUiMode = createSelector(
    selectContainerSize,
    (containerSize) => containerBetslipMapping[containerSize]
);

export const selectIsFullScreen = (state) => state.betslip.ui.isFullScreen;

export const selectDisplayFullslip = createSelector(
    [
        selectUiMode,
        selectIsFullScreen,
    ],
    (uiMode, isFullScreen) => {
        return uiMode === 'full' || isFullScreen === true;
    }
);

export const selectIsBetslipTab = (state) => state.betslip.ui.headerTab === 'betslip';

export const selectIsMyBetsTab = (state) => state.betslip.ui.headerTab === 'my-bets';

export const selectIsOpenBetsTab = (state) => state.betslip.ui.myBetsTab === 'openBets';

export const selectIsRecentlySettledBetsTab = (state) => state.betslip.ui.myBetsTab === 'settledBets';

export const selectPinnedTab = (state) => state.betslip.ui.pinnedTab;

export const selectIsPlacingBet = (state) => state.betslip.ui.placingBet;

export const selectIsBettingEnabled = (state) => state.betslip.ui.bettingEnabled;

export const selectShowModal = (state) => state.betslip.ui.showModal;

export const selectDisplaySinglesContent = createSelector(
    [
        selectPinnedTab,
        selectUserSelections,
    ],
    (pinnedTab, userSelections) => {
        return pinnedTab === 'singles' || userSelections.length <= 1;
    }
);

export const selectDisplayParlayContent = createSelector(
    [
        selectPinnedTab,
        selectIsParlayTabAvailable,
    ],
    (pinnedTab, isParlayTabAvailable) => {
        return pinnedTab === '' && isParlayTabAvailable;
    }
);

export const selectDisplayMultiplesContent = createSelector(
    [
        selectPinnedTab,
        selectIsMultiplesTabAvailable,
    ],
    (pinnedTab, isMultiplesTabAvailable) => {
        return pinnedTab === 'multiples'
            && isMultiplesTabAvailable;
    }
);

export const selectOpenBetsCount = (state) => state.betslip.ui.openBetsCounter;

const selectActiveTab = createSelector(
    [
        selectDisplaySinglesContent,
        selectDisplayParlayContent,
    ],
    (
        isSinglesTab,
        isParlayTab,
    ) => {
        if (isSinglesTab) {
            return 'singles';
        }
        if (isParlayTab) {
            return 'parlay';
        }

        return 'roundrobin';
    },
);

export const selectSource = createSelector(
    [
        selectUiMode,
        selectIsFullScreen,
        selectActiveTab,
    ],
    (uiMode, isFullScreen, activeTab) => {
        if (uiMode === 'baby' && !isFullScreen) {
            return 'babybetslip';
        }

        return activeTab;
    },
);

export const selectRefreshStatus = (state) => state.betslip.ui.refreshStatus;

export const selectBetslipGTMComponent = createSelector(
    selectDisplayFullslip,
    (displayFullslip) => {
        return displayFullslip ? 'new-betslip' : 'new-baby-betslip';
    }
);

export const selectIsRefreshingBetslipData = createSelector(
    selectRefreshStatus,
    (status) => status === 'pending'
);

export const selectDisplayBabyslip = createSelector(
    [
        selectIsBettingEnabled,
        selectDisplayFullslip,
        selectCountUserSelections,
    ],
    (isBettingEnabled, displayFullslip, countUserSelections) => {
        if (!isBettingEnabled || displayFullslip || !countUserSelections) {
            return false;
        }

        return true;
    }
);
