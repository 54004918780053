import { calculateAmericanPrice } from '~shared/components/sport-event/utils';
import { getOrderingParams } from '~shared/utils/selection-ordering';

// returns the correct format for events in SpectateStore
// on event page, no new events will be pushed so only need items that will change
export const eventNormalizer = (event) => ({
    id: String(event.id),
    active: Boolean(event.active),
    tradable: Boolean(event.tradable),
});

// returns the correct format for markets and markets_events in SpectateStore
export const marketsNormalizer = (markets, eventId) => {
    const normalizedMarkets = [];
    const normalizedMarketsEvents = [];
    markets.forEach((market) => {
        const normalizedMarket = {
            id: String(market.id),
            name: market.name,
            selection_schema: String(market.selection_schema),
            restrict_cashout: market.restrict_cashout,
            group_names: market.group_names,
        };

        const normalizedMarketEvent = {
            id: `${eventId}-${market.id}`,
            market_id: String(market.id),
            event_id: String(eventId),
            active: Boolean(market.active),
            tradable: Boolean(market.tradable),
        };

        if (market.default_line_selections.length > 0) {
            normalizedMarketEvent.default_line_selections = market.default_line_selections;
        }

        normalizedMarkets.push(normalizedMarket);
        normalizedMarketsEvents.push(normalizedMarketEvent);
    });

    return {
        normalizedMarkets,
        normalizedMarketsEvents,
    };
};

// returns the correct format for selections in SpectateStore
// and the selection ids that should be removed
export const selectionsNormalizer = (markets, eventId) => {
    const normalizedSelections = [];
    const selectionsToBeRemoved = [];

    markets.forEach((market) => {
        Object.keys(market.selections).forEach(selectionId => {
            const {
                name,
                price,
                fraction_price,
                decimal_price,
                sp_only,
                active,
                tradable,
                extra_info,
                outcome,
                specialoddsvalue = null,
                selection_type_id = null,
                type,
            } = market.selections[selectionId];

            if (!active || outcome !== 'UNSETTLED') {
                selectionsToBeRemoved.push(String(selectionId));
            }

            // inactive selections should not be added when WS sends
            if (active) {
                const normalizedSelection = {
                    id: String(selectionId),
                    name: name,
                    event_id: String(eventId),
                    market_id: String(market.id),
                    decimal_price,
                    fraction_price,
                    american_price: calculateAmericanPrice(price),
                    active,
                    tradable,
                    selection_type_id,
                    type,
                    specialoddsvalue,
                    sp_only,
                    outcome,
                    extra_info,
                    ordering_params : getOrderingParams(market.selections[selectionId], market.selection_schema),
                };

                normalizedSelections.push(normalizedSelection);
            }
        });
    });

    return {
        normalizedSelections,
        selectionsToBeRemoved,
    };
};
