import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { emit } from '../../shared/utils/event';
import { post } from '../../shared/utils/http';
import { buildSpectateUrl } from './Spectate';
import { changeBrowserURI } from '~spa/Utils/WrapperIntegration/Ucf';
import { getClientVersion } from '~spa/Utils/Spectate';
import {
    addToBetslipFromDeeplink,
} from '~Betslip/interface/interactions';
import { deeplinkRedirect } from '~shared/services/deeplink';
import { pushHistory } from '~spa/Router/memoryHistory';
import { getMarketingBrandId } from '~spa/SpectateConfig';
import { rewriteUrl } from '~shared/utils/uriAliases';

export const NAVIGATE_TO_EVENT_NAME = 'spa:navigate-to';
const TRANSLATION_URL_PATH = '/translation/urlPath';

const URL_CACHE_KEY_PREFIX = 'urlResolved:';

/**
 * This function will emit a custom event that is intercepted by the Spa core, which will
 * change the route accordingly.
 * This is done in order to circumvent the limitation of not being able to use react-router's
 * Link component in legacy/plain javascript code.
 *
 * @param {String} url The url to navigate to
 * @returns {void}
 */
export function navigateTo(url) {
    if (url === '#') {
        return;
    }

    handleUrl(url);
    emit(NAVIGATE_TO_EVENT_NAME, url);
}

/**
 * Creates a Session Storage key for which we store resolved url context
 * Uses clientVersion so we can effectively invalidate items when we release
 * Note that we must also invalidate redis cache (url/getSystemUrl endpoint)
 * or we'll get the stale data again
 *
 * @param {String} url  Url Path
 * @returns {String}    Cache key
 */
const composeUrlCacheKey = (url) => `${URL_CACHE_KEY_PREFIX}${getClientVersion()}:${url}`;

/**
 * Stores the resolved url context in Session Storage
 *
 * @param {String} url      Url Path
 * @param {Object} context  Resolved Context data
 * @returns {void}
 */
const storeUrlContextInCache = (url, context) => sessionStorage.setItem(
    composeUrlCacheKey(url),
    JSON.stringify(context)
);

/**
 * Reads the context data from the Session Storage for a given url
 *
 * @param {String} url      Url Path
 * @returns {Object|false}  Object containing resolved context data
 *                          False in case of missing key in Session Storage
 */
const getUrlContextFromCache = (url) => {
    const context = sessionStorage.getItem(
        composeUrlCacheKey(url)
    );

    if (context) {
        return JSON.parse(context);
    }

    return false;
};

/**
 * Get the context for a localized url path (ie: gets the correspoiding
 * english system url, plus its context data).
 * @param {String} urlPath The urlPath to contextualize
 * @returns {Object} The object containing the context data.
 */
async function getUrlContext(urlPath) {
    const cached = getUrlContextFromCache(urlPath);
    if (cached) {
        return cached;
    }

    const response = await post(buildSpectateUrl(TRANSLATION_URL_PATH), {
        urlPath,
        marketingBrandId: getMarketingBrandId(),
    });

    if (response.ok) {
        const {
            error = false,
            system_url_path: systemUrlPath,
            context,
            context_id: contextId,
            sport_id: sportId,
            sport_slug: sportSlug,
            sport_name: sportName,
            category_id: categoryId,
            category_name: categoryName,
            category_slug: categorySlug,
            tournament_id: tournamentId,
            tournament_name: tournamentName,
            tournament_slug: tournamentSlug,
            event_id: eventId,
            event_name: eventName,
            event_slug: eventSlug,
            event_extra_info: eventExtraInfo,
            original_url: originalUrl,
            scheduled_start: scheduledStart,
        } = await response.json();

        const urlContext = {
            error,
            systemUrlPath,
            context,
            contextId,
            sportId,
            sportName,
            sportSlug,
            categoryId,
            categoryName,
            categorySlug,
            tournamentId,
            tournamentName,
            tournamentSlug,
            eventId,
            eventName,
            eventSlug,
            eventExtraInfo,
            originalUrl,
            scheduledStart,
        };

        if (!error) {
            storeUrlContextInCache(urlPath, urlContext);
        }

        return urlContext;
    }

    return {
        error: false,
        systemUrlPath: urlPath,
        context: null,
        contextId: null,
        sportId: null,
        sportName: null,
        sportSlug: null,
        categoryId: null,
        categoryName: null,
        categoryuSlug: null,
        eventExtraInfo: null,
        tournamentId: null,
        tournamentName: null,
        tournamentSlug: null,
        eventId: null,
        eventName: null,
        eventSlug: null,
        originalUrl: null,
    };
}

/**
 * Retrieves the context id from the location state.
 * @param {Object} location The location object in the history object
 * @returns {Number|null} The context Id if available, null otherwise
 */
export function getContextIdFromLocation(location) {
    if (!isUndefined(location.state) && !isUndefined(location.state.contextId)) {
        return location.state.contextId;
    }

    return null;
}

const splitUrlToPathAndParams = (url) => url.split('?');

const isDeeplinkUrl = (url) => url.includes('deeplink');

const handleUrl = async (url) => {
    let [
        urlWithoutParams,
        queryStringParams,
    ] = splitUrlToPathAndParams(url);

    if (isDeeplinkUrl(urlWithoutParams)) {
        const urlParams = new URLSearchParams(queryStringParams);
        let selectionIds = urlParams.get('selections');

        if (selectionIds !== null) {
            selectionIds = JSON.parse(selectionIds);
            if (!isEmpty(selectionIds)) {
                const surveyId = urlParams.get('surveyId');
                addToBetslipFromDeeplink(selectionIds, surveyId);

                await deeplinkRedirect(selectionIds)
                    .then(({ url }) => {
                        urlWithoutParams = url;
                        handleBrowserURI(urlWithoutParams);
                    });
            } else {
                urlWithoutParams = '/';
                handleBrowserURI(urlWithoutParams);
            }
        }
    }

    const context = await getUrlContext(urlWithoutParams);
    pushHistory(context);
};

/**
 * Add the prefix to send to changeBrowserURI in the
 * message broker to update the address bar
 * @param {string} uri The uri string
 * @returns {null} null
 */
const handleBrowserURI = (uri) => {
        const uriBrowser = rewriteUrl(uri);
        changeBrowserURI(uriBrowser);
};
