import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    addSelection,
    removeSelection,
    clearSelections,
} from './userSelectionsSlice';

import {
    clearFreebets,
} from '~Betslip/utils/freeBetsActions';

const initialState = {
    userParlay: {
        stake: 0,
        each_way_selected: false,
        free_bet_id: null,
    },
    userSingles: {
        stake: 0,
        each_way_selected: false,
    },
};

const parlayTab = createSlice({
    name: 'betslip/parlayTab',
    initialState,
    reducers: {
        updateUserParlay: (state, action) => {
            Object.assign(state.userParlay, action.payload);
        },
        updateUserSingles: (state, action) => {
            Object.assign(state.userSingles, action.payload);
        },
    },
    extraReducers: {
        [addSelection]: () => initialState,
        [removeSelection]: () => initialState,
        [clearSelections]: () => initialState,
        [clearFreebets]: ({ userParlay }) => {
            Object.assign(userParlay, { free_bet_id: null });
        },
    },
});

export const {
    updateUserParlay,
    updateUserSingles,
} = parlayTab.actions;

export default parlayTab.reducer;
