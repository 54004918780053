import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    getRecommendations,
} from '../thunks/getRecommendations';

import {
    clearSelections,
} from '~Betslip/slices/userSelectionsSlice';

import {
    goBackToBetslip,
} from '~Betslip/slices/betReceiptSlice';

const initialState = [];

const recommendations = createSlice({
    name: 'betslip/recommendations',
    initialState,
    reducers: {
        updateRecommendations: (state, action) => {
            const recommendations = action.payload;
            Object.keys(recommendations).forEach((id) => {
                const recommendation = state.find(rec => rec.id === Number(id));
                if (recommendation && recommendations[id]) {
                    Object.assign(recommendation, recommendations[id]);
                }
            });
        },
    },
    extraReducers: {
        [getRecommendations.fulfilled]: (state, action) => {
            return action.payload;
        },
        [clearSelections]: (state, action) => {
            const { isBetReceipt } = action.payload;
            if (!isBetReceipt) {
                return initialState;
            }

            return state;
        },
        [goBackToBetslip]: () => initialState,
    },
});

export const {
    updateRecommendations,
} = recommendations.actions;

export default recommendations.reducer;
