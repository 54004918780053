import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

export const getTotalOpenBets = createAsyncThunk(
    'betslip/getTotalOpenBets',
    async (_, { rejectWithValue }) => {
        const url = buildSpectateUrl('/betslip/getTotalOpenBets/');

        const response = await get(url);
        if (!response.ok) {
            rejectWithValue(0);
        }

        return response.json();
    }
);
