import { createAction } from '@reduxjs/toolkit';

export const inplayScoreboardUpdate = createAction('inplayScoreboardUpdate', (data) => {
    const {
        id,
        active,
        tradable,
        extra_info,
        match_status,
    } = data.events[0];

    const normalizedEvent = {
        id: String(id),
        tradable,
        active,
    };

    if (match_status) {
        normalizedEvent.match_status = match_status;
    }

    let normalizedScoreBoard = {};

    if (extra_info) {
        normalizedScoreBoard = {
            id: String(id),
            ...extra_info,
        };
    }

    return {
        payload : {
            event: normalizedEvent,
            scoreboard: normalizedScoreBoard,
        },
    };
});
