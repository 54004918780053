import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    refreshFreebets,
} from '../thunks/refreshFreebets';

import {
    updateSelection,
    removeSelection,
    clearSelections,
    updateAllSelections,
} from '~Betslip/slices/userSelectionsSlice';

import {
    updateUserParlay,
} from '~Betslip/slices/parlayTabSlice';

import {
    editUserMultiple,
} from '~Betslip/slices/userMultiplesSlice';

import {
    clearFreebets,
} from '~Betslip/utils/freeBetsActions';

const initialState = {
    freebets: [],
    selectedFreebets: [],
};

const getIsMultiple = (id) => {
    const regexOnlyWords = /^[a-z]+$/i;

    return isNaN(id) && Boolean(id.match(regexOnlyWords));
};

const updateSelectedFreebets = (id, freebetId, state) => {
    // remove selected multi freebets when selections change in the betslip
    // id is a number for single selections and a string for multis (i.e. parlay, doubles, trebles)
    if (!freebetId && !id) {
        state.selectedFreebets = state.selectedFreebets.filter(selectedFreebet => {
            const { id: selectedId } = selectedFreebet;

            return !getIsMultiple(selectedId);
        });
    }

    if (freebetId) {
        state.selectedFreebets.push({
            id,
            freebet: freebetId,
        });
    } else {
        state.selectedFreebets = state.selectedFreebets.filter(selectedFreebet => selectedFreebet.id !== id);
    }
};

const freeBets = createSlice({
    name: 'betslip/freeBets',
    initialState,
    extraReducers: {
        [refreshBetslip.fulfilled]: (state, action) => {
            state.freebets = action.payload.freebets;
            updateSelectedFreebets(undefined, undefined, state);
        },
        [refreshFreebets.fulfilled]: (state, action) => {
            state.freebets = action.payload;
        },
        [updateUserParlay]: (state, action) => {
            const { free_bet_id: freebetId } = action.payload;
            const id = 'parlay';
            updateSelectedFreebets(id, freebetId, state);
        },
        [updateSelection]: (state, action) => {
            const {
                id,
                free_bet_id: freebetId,
            } = action.payload;

            updateSelectedFreebets(id, freebetId, state);
        },
        [updateAllSelections]: (state) => {
            state.selectedFreebets = state.selectedFreebets.filter(selectedFreebet => {
                const { id: selectedId } = selectedFreebet;

                return getIsMultiple(selectedId);
            });
        },
        [editUserMultiple]: (state, action) => {
            const {
                id,
                free_bet_id: freebetId,
            } = action.payload;

            updateSelectedFreebets(id, freebetId, state);
        },
        [removeSelection]: (state, action) => {
            state.selectedFreebets = state.selectedFreebets.filter(
                selectedFreebet => selectedFreebet.id !== action.payload
            );
        },
        [clearSelections]: () => initialState,
        [clearFreebets]: () => initialState,
    },
});

export default freeBets.reducer;
