import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';

export const tournamentsAdapter = createEntityAdapter();

const tournamentsSlice = createSlice({
    name: 'tournaments',
    initialState: tournamentsAdapter.getInitialState(),
    reducers: {
        addTournament: tournamentsAdapter.addOne,
        removeTournament: tournamentsAdapter.removeOne,
    },
    extraReducers: {
        [inplayEventData.fulfilled] : (state, { payload : { tournament } }) => {
            return tournamentsAdapter.addOne(state, tournament);
        },
    },
});

export const { addTournament, removeTournament } = tournamentsSlice.actions;
export default tournamentsSlice.reducer;
