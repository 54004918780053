import { v4 as uuidv4 } from 'uuid';

/**
 * UCF Identifier
 * @type {String}
 */
const SPECTATE_IDENTIFIER = 'spectateSportClient';

/**
 * Player Has Pending Action Identifier for 888
 * @type {Integer}
 */
const PENDING_ACTION_APP_ID = 2;

/**
 * Unified Client SDK Lib
 */
let UC = undefined;

/**
 * Sets the reference to UC Lib
 * @param  {Object} ucLib     UC Lib Object
 * @return {void}
 */
export const setUcLib = (ucLib) => {
    UC = ucLib;
    setMessageBrokerChannels(ucLib.channels);
};

/**
 * 888 Wrapper Message Broker Channels
 * @type {Object}
 */
let messageBrokerChannels = {};

/**
 * Gets the topic object from Message Broker for a given channel and topic.
 * @param  {String}   channel   Channel name
 * @param  {String}   topic     Topic name
 * @throws {Error}              Error is thrown when channel or topic does not exist
 * @returns {Object}            Topic
 */
const getMessageBrokerTopic = (channel, topic) => {
    const mbTopic = messageBrokerChannels?.[channel].topics?.[topic];

    if (!mbTopic) {
        throw new Error(`Message broker channel [${channel}], topic [${topic}] not found`);
    }

    return mbTopic;
};

/**
 * Subscribes for updates from a given channel's topic
 * @param  {String}   channel   Channel name
 * @param  {String}   topic     Topic name
 * @param  {Function} callback  Callback function which runs
 *                              when subscriber receives a message
 * @return {Object|null}        Topic subscription
 *                              null in case of missing topic
 */
const subscribe = (channel, topic, callback) => {
    try {
        return getMessageBrokerTopic(channel, topic).subscribe(callback);
    } catch (error) {
        console.error(error); //eslint-disable-line
    }

    return null;
};

/**
 * Publishes data to given channel's topic
 * @param  {String} channel Channel name
 * @param  {String} topic   Topic name
 * @param  {Any}    [data]  Data to be published
 * @return {void}
 */
const publish = (channel, topic, data = undefined) => {
    try {
        getMessageBrokerTopic(channel, topic).publish(
            {
                publisher: SPECTATE_IDENTIFIER,
            },
            data
        );
    } catch (error) {
        console.error(error);  //eslint-disable-line
    }
};

/**
 * Sets the messageBrokerChannels given from 888 Wrapper through init function.
 * @param  {Object} channels     888 MessageBroker Channels
 * @return {void}
 */
const setMessageBrokerChannels = (channels) => {
    messageBrokerChannels = channels;
};

/**
 * Notifies UCF that our client JS is loaded and runs the callback method
 * passed as a param.
 *
 * @param {Function} callback   Callback function
 * @return {void}
 */
export const clientLoaded = (callback) => UC.init.onClientLoad(callback);

/**
 * Awaits for UC SDK Readiness
 *
 * @returns {Promise} UC SDK Readiness
 */
export const ucSdkReady = async () => {
    if (window.libraryManager?.UCSDKLibrary.ready) {
        const isUcSdkReady = await window.libraryManager.UCSDKLibrary.ready;

        return isUcSdkReady;
    }

    // If new ucSdk is not available it means
    // that `window.uc` must be available in the window scope
    return Promise.resolve(window.uc);
};

/**
 * Awaits for UC Data Store Readiness.
 * If the Unified Client version (that Spectate Client is launched from)
 * does not support yet ucDataStoreReady - resolve the Promise
 * and init Spectate Client as usual.
 *
 * @returns {Promise} UC Data Store Readiness
 */
export const ucDataStoreReady = async () => {
    if (window.userDataStoreDO?.promise) {
        const userDataStoreIsReady = await window.userDataStoreDO.promise;

        return userDataStoreIsReady;
    }

    if (typeof UC.dataStoreReady !== 'undefined') {
        const isReady = await UC.dataStoreReady;

        return isReady;
    }

    return Promise.resolve();
};

/**
 * Gets the UCF client settings.
 *
 * @return {Object}    UCF Client Settings
 */
export const getClientSettings = () => UC.getClientSettings();

/**
 * Notifies UCF that our client JS load failed.
 * @param {String} reason    Description of failure reason
 * @return {void}
 */
export const clientInitFailed = (reason) => {
    const data = {
        'error': {
            'errorCode': '500121',
            'errorDescription': reason,
        },
    };

    publish('session', 'clientInitFailed', data);
};

/**
 * Notifies UCF that our client JS load succeeded.
 *
 * @return {void}
 */
export const clientInitSucceeded = () => publish(
    'session', 'clientInitSucceeded'
);

export const subscribedToMessageBroker = () => publish(
    'session', 'subscribedToMessageBroker'
);

export const onUserAuthenticate = (callback) => subscribe(
    'identity', 'authenticate', callback
);

export const userAuthenticationFailed = (reason) => {
    const data = {
        'error': {
            'errorCode': '500121',
            'errorDescription': reason,
        },
    };

    publish('identity', 'userAuthenticationFailed', data);
};

export const userAuthenticationSucceeded = () => publish(
    'identity', 'userAuthenticationSucceeded'
);

export const onUserLogout = (callback) => subscribe(
    'identity', 'logout', callback
);

export const kickUser = () => publish(
    'session', 'inactivityTimeout'
);

export const onPlayerSegmentationUpdate = (callback) => subscribe(
    'player', 'playerSegmentationUpdate', callback
);

export const onNavigate = (callback) => subscribe(
    'navigation', 'Navigate', callback
);

export const onNavigateByCode = (callback) => subscribe(
    'router', 'NavigateByCode', callback
);

export const onDeactivate = (callback) => subscribe(
    'navigation', 'Deactivate', callback
);

export const setPlayerOddsFormat = (oddsFormat) => publish(
    'player', 'playerPreferences', oddsFormat
);

export const onOddsFormatUpdate = (callback) => subscribe(
    'userinteraction', 'oddsFormatUpdate', callback
);

export const onCustomerThemeModeUpdate = (callback) => subscribe(
    'userinteraction', 'themeModeUpdate', callback
);

export const onFavouriteUpdate = (callback) => subscribe(
    'player', 'favouriteUpdate', callback
);

export const onPostRegistrationDepositClosed = (callback) => subscribe(
    'userinteraction', 'postRegistrationDepositClosed', callback
);

export const updateWrapperOpenBetsNumber = (number) => {
    const data = {
        'NumberOfOpenBets': number,
    };

    publish('sport', 'betsUpdate', data);
};

export const onOpenMyBetsRequest = (callback) => subscribe(
    'sport', 'openMyBets', callback
);

export const getToken = () => publish(
    'identity', 'getToken'
);

export const onInitKambi = (callback) => subscribe(
    'identity', 'initThirdParty', callback
);

export const openLoginModal = () => publish(
    'player', 'openLogin'
);

export const betPlacementSucceeded = () => publish(
    'wallet', 'PlayerAction_BetSucceeded'
);

export const playerHasPendingAction = () => {
    const data = {
        appID: PENDING_ACTION_APP_ID,
        appData: null,
    };

    publish('navigation', 'openApp', data);
};

export const initializePokerBlast = (data) => {
    publish('navigation', 'openApp', data);
};

export const onPokerBlastInitSuccess = (callback) => subscribe(
    'ui', 'CreateAppContainer', callback
);

// Subscription to errors that will happen after openApp is received by 888
// and init data was passed to Pokerblast widget (inside pokerblast flow)
export const onPokerBlastError = (callback) => subscribe(
    'ui', 'ShowAppError', callback
);

// Subscription to errors that will happen after openApp is received by 888
// but BEFORE init data was passed to Pokerblast widget
// (outside pokerblast flow, i.e. openApp data validation)
export const onPokerBlastInitError = (callback) => subscribe(
    'session', 'appError', callback
);

export const onPokerBlastAppInitError = (callback) => subscribe(
    'ui', 'RemoveAppContainer', callback
);

export const onPokerBlastContainerClose = (data) => {
    publish('session', 'appClosed', data);
};

export const deepLinkCasino = (config) => {
    const data = {
        deeplink: {
            action: 'game-real',
            ...config,
        },
    };

    publish('crossSell', 'CTA_CasinoClient_Open', data);
};

export const onAddToBetslip = (callback) => subscribe(
    'player', 'addToBetslip', callback
);

export const changeBrowserURI = (uri) => {
    const data = { uri };

    publish('navigation', 'changeBrowserURI', data);
};

export const updatePlayerFavouriteSports = (favouriteSports = [], errorMsg = '') => {
    const data = {
        error_msg: errorMsg,
        favourite_sports: favouriteSports,
    };
    publish('player', 'favouriteSports', data);
};

export const onOpenFavouriteSportsModal = (callback) => subscribe(
    'ui', 'openFavouriteSportsModal', callback
);

export const openFavouriteSportsModal = () => publish('ui', 'openFavouriteSportsModal');

export const onPlayerFavouriteSportsUpdate = (callback) => subscribe(
    'player', 'favouriteSports', callback
);

export const navigatePage = (url) => {
    const data = { url };

    publish('router', 'NavigateByCode', data);
};

export const onUserBalanceUpdate = (callback) => subscribe(
    'wallet', 'BalanceUpdated', callback
);

export const openDepositWidget = () => {
    const data = {
        actionID: 'deposit',
        correlationID:  uuidv4(),
        launchInfo: {
            businessCorrelationID:  uuidv4(),
            sequentialCorrelationID: 1,
            channel: {
                area: 'Betslip',
                source: 'Spectate Client',
                element: 'Deposit Button',
            },
            appSpecificParameters: {},
            trigger: 'userSelection',
            sourceAppID: 'SPORT_OFFERING',
        },
        actionData: {},
    };

    publish('navigation', 'performAction', data);
};

export const onGeoLocationStatusUpdate = (callback) => subscribe(
    'geolocation', 'geolocationStatus', callback
);

export const openGeoCheckRetryModal = () => {
    const data = {
        errorCode: 413,
    };

    publish('player', 'CTA_Bet_Failed', data);
};

export const setSiteScrollDisabled = (isDisabled) => publish('ui', 'scrollActivation', { selector: '.ucf_mobile', isDisabled });

export const onClientStateChanged = (callback) => subscribe(
    'userinteraction', 'clientStateChanged', callback
);

export const setSiteScrollDisabledDesktop = (isDisabled) => publish('ui', 'scrollActivation', { selector: '.uc-content-area', isDisabled });

export const sendHapticFeedback = (type) => publish(
    'userinteraction', 'hapticFeedback', { type }
);
