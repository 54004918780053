import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    clearFreebets,
} from '~Betslip/utils/freeBetsActions';

import {
    addSelection,
    removeSelection,
    clearSelections,
} from './userSelectionsSlice';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import { systemDecimalFormat } from '~Betslip/utils/numberFormatters';

const initialState = [];

const initialMultipleData = {
    stake: 0,
    each_way_selected: false,
    edit: true,
    free_bet_id: null,
};

const userMultiples = createSlice({
    name: 'betslip/userMultiples',
    initialState,
    reducers: {
        removeMultiple: (state, action) => {
            return state.filter(
                multiple => multiple.id !== action.payload
            );
        },
        addUserMultiple: (state, { payload: id }) => {
            const payload = {
                ...initialMultipleData,
                id,
            };

            state.unshift(payload);
        },
        editUserMultiple: (state, { payload }) => {
            const {
                id,
            } = payload;

            const multipleToEdit = state.find(multiple => multiple.id === id);
            if (multipleToEdit) {
                Object.assign(multipleToEdit, payload);
            }
        },
        saveFormattedValue: (state, action) => {
            const id = action.payload;

            const multipleToFormat = state.find(multiple => multiple.id === id);
            if (multipleToFormat) {
                const { stake } = multipleToFormat;
                if (stake || stake === '') {
                    Object.assign(multipleToFormat, {
                        stake: systemDecimalFormat(stake),
                    });
                }
            }
        },
        // Dispatching toggleEditor with no payload means the editor will be closed
        toggleEditor: (state, action) => {
            return state
                .filter(({stake, free_bet_id}) => {
                    const stakeIsNotZero = Boolean(Number(stake)) || free_bet_id;

                    return stakeIsNotZero;
                })
                .map(multiple => ({
                    ...multiple,
                    edit: multiple.id === action.payload,
                }));
        },
    },
    extraReducers: {
        [addSelection]: () => initialState,
        [removeSelection]: () => initialState,
        [clearSelections]: () => initialState,
        [refreshBetslip.fulfilled]: (state, action) => {
            const multipleBeingEdited = state.find(multiple => multiple.edit);

            if (multipleBeingEdited) {
                const { available_bet_types: availableBetTypes } = action.payload;
                multipleBeingEdited.id = availableBetTypes[availableBetTypes.length - 1].id;
            }
        },
        [clearFreebets]: (state) => (
            state.filter(({free_bet_id}) => free_bet_id === null)
        ),
    },
});

export const {
    removeMultiple,
    addUserMultiple,
    editUserMultiple,
    saveFormattedValue,
    toggleEditor,
} = userMultiples.actions;

export default userMultiples.reducer;
