import { createAction } from '@reduxjs/toolkit';
import {
    eventNormalizer,
    marketsNormalizer,
    selectionsNormalizer,
} from '~shared/utils/dataNormalisers';

export const inplayEventUpdate = createAction('inplayEventUpdate', (data) => {
    const { markets, ...event } = data.events[0];

    const normalizedEvent = eventNormalizer(event);
    const { normalizedMarkets, normalizedMarketsEvents } = marketsNormalizer(markets, event.id);
    const { normalizedSelections, selectionsToBeRemoved } = selectionsNormalizer(markets, event.id);

    return {
        payload : {
            event: normalizedEvent,
            markets: normalizedMarkets,
            marketsEvents: normalizedMarketsEvents,
            selections: normalizedSelections,
            selectionsToBeRemoved,
        },
    };
});
