import { createAsyncThunk } from '@reduxjs/toolkit';

import { post } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

import { betPlacementSucceeded } from '~spa/Utils/WrapperIntegration/Ucf';

import {
    selectCountUserSelections,
    selectEmptyStakeSelections,
    selectCastOrScorecastSelections,
} from '~Betslip/selectors/userSelectionsSelectors';

import {
    selectSelectionDetails,
    selectSuspendedSelections,
} from '~Betslip/selectors/selectionDetailsSelectors';

import {
    selectBetStructure,
} from '~Betslip/selectors/betPlacementSelectors';

import {
    selectDisplaySinglesContent,
    selectPinnedTab,
    selectUiMode,
} from '~Betslip/selectors/uiSelectors';

import {
    formatSelectionsToRetain,
    formatSuspendedSelectionsToRetain,
} from '~Betslip/utils/selectionFormatters';

import {
    createBetReceiptSlice,
} from '~Betslip/utils/receiptFormatters';

import {
    clearBetslipSelections,
    addSelectionsToBetslip,
} from '~Betslip/interface/interactions';

import {
    sendHapticFeedbackSuccess,
} from '~spa/Utils/HapticFeedback';

const getSelectionsToRetainInBR = (state, response) => {
    // empty stake flags need to be retained
    const {
        emptySingles,
        emptySinglesFlags,
    } = selectEmptyStakeSelections(state);
    const isSinglesTab = selectDisplaySinglesContent(state);

    const { bets: allBets, errors } = response;

    // suspended selections before bet-placement need to he retained
    const existingSuspendedSelections = selectSuspendedSelections(state);
    const {
        suspendedToRetain,
        suspendedToRetainFlags,
     } = formatSuspendedSelectionsToRetain(existingSuspendedSelections, errors);

    // declined selections after bet-placement need to be retained
    const declinedBets = allBets.filter(({success}) => !success);
    const {
        selectionsToRetain: declinedSelectionIds,
        selectionFlags: declinedSelectionFlags,
    } = formatSelectionsToRetain(declinedBets);

    // fc/tc/sc should be retained if placed bet_type ignored them
    const pinnedTab = selectPinnedTab(state);
    const singlesBetTypes = ['singles', 'forecast', 'tricast', 'scorecast'];
    const singlesPlaced = response.bets.some(
        ({bet_type}) => singlesBetTypes.includes(bet_type.toLowerCase()),
    );

    const toRetain = selectCastOrScorecastSelections(state);
    let scFcTcToRetain = [];
    let scFcTcToRetainFlags = [];

    if (pinnedTab === 'multiples' && !singlesPlaced) {
        // retain any fc/tc/sc
        scFcTcToRetain = toRetain.map(({id}) => id);
        scFcTcToRetainFlags = toRetain.map((selection) => ({
            id: selection.id,
            is_any: selection.is_any,
            is_cast_market: selection.is_cast_market,
            is_scorecast: selection.is_scorecast,
            bet_locate: selection.bet_locate,
        }));
    }

    const selectionsToRetainInBetReceipt = [
        ...(isSinglesTab ? emptySingles : []),
        ...declinedSelectionIds,
        ...suspendedToRetain,
        ...scFcTcToRetain,

    ];
    const selectionFlagsReceipt = [
        ...declinedSelectionFlags,
        ...emptySinglesFlags,
        ...suspendedToRetainFlags,
        ...scFcTcToRetainFlags,

    ];

    return {
        selectionsToRetainInBetReceipt,
        selectionFlagsReceipt,
    };
};

/**
 * Check if we have any overrides for the bet payload
 * @param {Object} requestParams bet request params
 * @param {Object} placeBetOverrides overriding params
 * @returns {Void} none - mutates requestParams argument
 */
const doPlaceBetOverrides = (requestParams, placeBetOverrides) => {
    if (!Object.keys(placeBetOverrides).length) {

        return;
    }

    const { bets } = requestParams;
    const { priceChangeSelections = [] } = placeBetOverrides;
    // check changed prices. e.g. price changed in BE while XHR request was pending
    priceChangeSelections.forEach(({ id: selectionIdToChange, new_price_decimal: newPrice }) => {
        const { selections } = bets;
        selections.some((bet, betIndex) => {
            if (selectionIdToChange === bet.id) {
                selections[betIndex] = { ...selections[betIndex], price: newPrice };

                return true;
            }

            return false;
        });
    });
};

export const placeBet = createAsyncThunk(
    'betslip/placeBet',
    async (placeBetOverrides, { getState }) => {
        const state = getState();
        try {
            const pinnedTab = selectPinnedTab(state);
            const betStructure = selectBetStructure(state);
            const selectionDetails = selectSelectionDetails(state);
            const uiMode = selectUiMode(state);

            const url = buildSpectateUrl('/betslip/bet');
            const requestParams = {
                bets: betStructure,
            };

            if (placeBetOverrides) {
                doPlaceBetOverrides(requestParams, placeBetOverrides);
            }

            const requestResponse = await post(url, requestParams);
            const result = await requestResponse.json();

            const betReceiptSlice = await createBetReceiptSlice(result, selectionDetails);

            const {
                response, errno, success,
            } = result;

            if (success) {
                const {
                    selectionsToRetainInBetReceipt,
                    selectionFlagsReceipt,
                } = getSelectionsToRetainInBR(state, response);

                clearBetslipSelections();
                betPlacementSucceeded();
                sendHapticFeedbackSuccess();
                if (selectionsToRetainInBetReceipt.length > 0) {
                    addSelectionsToBetslip(selectionsToRetainInBetReceipt, pinnedTab, selectionFlagsReceipt);
                }
            }

            return {
                ...betReceiptSlice,
                uiMode,
                pinnedTab,
                errno,
            };
        } catch (error) {
            /**
             * We need to log the error in this catch,
             * otherwise the error is caught by the thunk and not displayed at all
             */

            // eslint-disable-next-line
            console.error('betslip/placeBet - rejection catch:', error);

            return {
                bets: [],
                errors: [{
                    id: 'UNKNOWN_ERROR',
                }],
                errno: 500, // for modal to show default error
            };
        }
    },
    {
        condition: (_, { getState }) => {
            const count = selectCountUserSelections(getState());

            return count > 0;
        },
    }
);
