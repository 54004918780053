import i18n from './i18n';
import { DATE_FNS_MAPPING } from '~shared/locales/customLanguageMappings';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en';
import sv from 'date-fns/locale/sv';
import ru from 'date-fns/locale/ru';
import fi from 'date-fns/locale/fi';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import nl from 'date-fns/locale/nl';
import ro from 'date-fns/locale/ro';
import ar from 'date-fns/locale/ar';

const DATE_FNS_LOCALES = { da, en, sv, ru, fi, pt, es, fr, de, nl, ro, ar };

/**
 * Gets the client (browser) timezone
 *
 * @returns {String} object that offset + dst data
 */
function getClientTimezone() {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch(e) {
        return null;
    }
}

/**
 * get locale timezone
 * @returns {Object} locale timezone object
 */
function getLocaleTimeZone() {
    const _2LettersLanguage = DATE_FNS_MAPPING[i18n.getLanguage()];

    return DATE_FNS_LOCALES[_2LettersLanguage];
}

export {
    getClientTimezone,
    getLocaleTimeZone,
};
