import {
    setStateBatch,
} from '~shared/utils/state';

import {
    USER_LOCALE_STATE_KEY,
    USER_TIMEZONE_STATE_KEY,
    USER_LANGUAGE_ISO_STATE_KEY,
    BRAND_ID_STATE_KEY,
    SUB_BRAND_ID_STATE_KEY,
    PRODUCT_PACKAGE_ID_STATE_KEY,
    USER_DEVICE_STATE_KEY,
    WRAPPER_IS_MOBILE_STATE_KEY,
    WRAPPER_IS_HYBRID_STATE_KEY,
    WRAPPER_IS_FTD_STATE_KEY,
    WRAPPER_IS_PROMOTION_PERMITTED_STATE_KEY,
    WRAPPER_IS_PROMOTION_RESTRICTED_STATE_KEY,
    WRAPPER_IS_VIP_STATE_KEY,
    WRAPPER_RMS_STATE_KEY,
    WRAPPER_SERIAL_STATE_KEY,
    WRAPPER_OS_NAME,
    WRAPPER_OS_VERSION,
    WRAPPER_PAGE_CORRELATION_ID_STATE_KEY,

    setCountryIso,
    setCountryState,
    setCurrencyIso,
    setHasKambiBets,
    setRegulationTypeId,
    setUserMode,
    setCurrentTheme,
} from '~shared/utils/user';

const CLIENT_STETTING_OVERWRITE_PREFIX = 'spectate:overwrite:ClientSettings:';

const clientSettingsActors = {
    // client settings that goes to client state via setStateBatch
    brand_id: BRAND_ID_STATE_KEY,
    sub_brand_id: SUB_BRAND_ID_STATE_KEY,
    product_package_id: PRODUCT_PACKAGE_ID_STATE_KEY,
    language: USER_LANGUAGE_ISO_STATE_KEY,
    locale: USER_LOCALE_STATE_KEY,
    timezone: USER_TIMEZONE_STATE_KEY,
    deviceTypeID: USER_DEVICE_STATE_KEY,
    is_mobile: WRAPPER_IS_MOBILE_STATE_KEY,
    is_hybrid: WRAPPER_IS_HYBRID_STATE_KEY,
    is_ftd: WRAPPER_IS_FTD_STATE_KEY,
    is_promotion_permitted: WRAPPER_IS_PROMOTION_PERMITTED_STATE_KEY,
    is_promotion_restricted: WRAPPER_IS_PROMOTION_RESTRICTED_STATE_KEY,
    is_vip: WRAPPER_IS_VIP_STATE_KEY,
    rms: WRAPPER_RMS_STATE_KEY,
    serial: WRAPPER_SERIAL_STATE_KEY,
    osName: WRAPPER_OS_NAME,
    osVersion: WRAPPER_OS_VERSION,
    page_correlation_id: WRAPPER_PAGE_CORRELATION_ID_STATE_KEY,

    // client settings to use callbacks
    country: setCountryIso,
    country_state: setCountryState,
    currency_code: setCurrencyIso,
    has_kambi_bets: setHasKambiBets,
    regulation_type_id: setRegulationTypeId,
    themeMode: setCurrentTheme,
    user_mode: setUserMode,
};

const map888paramsToSpectate = {
    brandID: 'brand_id',
    subBrandID: 'sub_brand_id',
    marketingBrandID: 'marketing_brand_id',
    productPackageID: 'product_package_id',
    languageCode: 'language',
    country: 'country',
    currency: 'currency_code',
    locale: 'locale',
    regulationTypeID: 'regulation_type_id',
    timeZoneUTCOffset: 'timezone',
    userMode: 'user_mode',
    isMobile: 'is_mobile',
    isHybrid: 'is_hybrid',
    isFTD: 'is_ftd',
    isPromotionPermitted: 'is_promotion_permitted',
    isPromotionRestricted: 'is_promotion_restricted',
    isVIP: 'is_vip',
    RMS: 'rms',
    serial: 'serial',
    state: 'country_state',
    HasKambiBets: 'has_kambi_bets',
    osName: 'osName',
    osVersion: 'osVersion',
    pageCorrelationId: 'page_correlation_id',
    themeMode: 'themeMode',
};

// eslint-disable-next-line no-console
const raiseWarning = (msg) => console.warn(msg);

const normalizeParamName = (param) => {
    let paramName = param;
    if (map888paramsToSpectate.hasOwnProperty(param)) {
        paramName = map888paramsToSpectate[param];
    }

    return paramName;
};

const getActorForParam = (param) => {
    let internalParamName = normalizeParamName(param);

    if (!clientSettingsActors.hasOwnProperty(internalParamName)) {
        raiseWarning(`Given param [${internalParamName} (${param})] is not allowed`);

        return undefined;
    }

    return clientSettingsActors[internalParamName];
};

/**
 * This method allows developers and QA to overwrite some settings
 * which come from Wrapper.
 *
 * For example:
 *     sessionStorage.setItem('spectate:overwrite:ClientSettings:language', 'eng')
 *
 * @param {String} param            Client setting spectate param name (normalized)
 * @param {String|Integer} value    Client setting value
 *
 * @return {String|Integer}         Returns value whether is has been overwritten
 *                                  or not
 */
const overwriteClientSetting = (param, value) => {
    const key = `${CLIENT_STETTING_OVERWRITE_PREFIX}${param}`;
    const overwrittenValue = sessionStorage.getItem(key);
    if (overwrittenValue !== null) {
        return overwrittenValue;
    }

    return value;
};

const normalizeClientSetting = (param, value) => {
    const actor = getActorForParam(param);
    if (actor === undefined) {
        return undefined;
    }

    const outputKey = typeof actor === 'function'
        ? 'callback'
        : 'stateKey';

    return {
        [outputKey]: actor,
        settingValue: overwriteClientSetting(normalizeParamName(param), value),
    };
};

export const processClientSettings = (clientSettings) => {
    const batchData = {};

    Object.entries(clientSettings).forEach(
        ([param, value]) => {
            const normalized = normalizeClientSetting(param, value);
            if (normalized) {
                const {callback, stateKey, settingValue} = normalized;

                if (callback) {
                    callback(settingValue);
                } else {
                    batchData[stateKey] = settingValue;
                }
            }
        }
    );

    setStateBatch(batchData);
};
