import {
    getCurrencyIso,
    getLocale,
} from './user';
import { DEFAULT_DECIMAL_PLACES } from './constants';
import { formatUsingLegacy, isLegacy, legacyDecimalSeparator } from './currencyFormatterLegacy';

export const getDecimalSeparator = (locale = getLocale()) => {
    const numberWithDecimalSeparator = 1.1;

    return !isLegacy
        ? Intl.NumberFormat(locale)
            .formatToParts(numberWithDecimalSeparator)
            .find(part => part.type === 'decimal')
            .value
        : legacyDecimalSeparator(getCurrencyIso());
};

export const localizeNumber = (number, locale = getLocale()) => {
    return Intl.NumberFormat(locale).format(number);
};

export const deLocalizeNumberString = (localizedNumberString = '') => {
    return localizedNumberString.replace(',', '.').replace(' ', '').replace('\xa0', '');
};

const numberFormat = (
    value,
    currency,
    locale,
    options,
) => {
    return !isLegacy
        ? new Intl.NumberFormat(locale || getLocale(), {
                style: options.withCurrencySymbol ? 'currency' : 'decimal',
                currency,
                useGrouping: options.withThousandSeperator,
                minimumFractionDigits: options.decimalPlaces,
                maximumFractionDigits: options.decimalPlaces,
            }).format(value)
        : formatUsingLegacy(
            value,
            locale,
            currency,
            options.withCurrencySymbol,
            options.withThousandSeperator,
            options.decimalPlaces,
        );
};

/**
 * This function is needed for the way the decimals (stake, return,
 * etc) are computed on the server.
 * This function rounds a number to the format used by the
 * server. It should be called after each operation on the number.
 * @param {Number} n the number to round
 * @returns {Number} The rounded number to the second decimal
 */
 export const bsNumber = n => Math.round(n * 100) / 100;

export default (
    value,
    currency = getCurrencyIso(),
    locale,
    withCurrencySymbol = true,
    withThousandSeperator = true,
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
    ) => {

    if (isNaN(value)) {return value;}

    const roundedValue = Number(value).toFixed(decimalPlaces);

    return numberFormat(
        roundedValue,
        currency || getCurrencyIso(),
        locale || getLocale(), {
        withCurrencySymbol,
        withThousandSeperator,
        decimalPlaces,
    });
};
