/**
 * bet error ids mapping
 * these keys are used for localisation
 */
 const BET_ERRORS = {
    DEFAULT_FALLBACK: 'your_bet_declined',
    INCOMPATIBLE_SELECTION: 'incompatible_sel',
    PRICE_CHANGED: 'price_changed',
    INVALID_FREE_BET: 'invalid_free_bet',
    EACH_WAY_RESTRICTED: 'ew_restricted',
    SELECTION_EACH_WAY_UNAVAILABLE: 'sel_ew_unavailable',
    HORSE_NOT_TRADABLE: 'horse_not_tradable',
    CUSTOMER_SUSPENDED: 'cust_suspended',
    CUSTOMER_EXCEEDED_CUMULATIVE_MAX_STAKE: 'cust_exceeded_cumulative_max_stake',
    CUSTOMER_EXCEEDED_MAX_STAKE: 'exceeded_max_stake',
    CUSTOMER_EXCEEDED_MAX_STAKE_MULTIBET: 'exceeded_multi_max_stake',
    CUSTOMER_EXCEEDED_MAX_STAKE_EW: 'exceeded_max_stake',
    CUSTOMER_EXCEEDED_MIN_STAKE: 'exceeded_min_stake',
    CUSTOMER_EXCEEDED_MIN_STAKE_MULTIBET: 'exceeded_multi_min_stake',
    CUSTOMER_EXCEEDED_MIN_STAKE_EW: 'exceeded_min_stake',
    E_MTS_MAX_PAYOUT_EXCEEDED: 'exceeded_max_stake',
    E_MTS_CUSTOMER_LIABILITY_EXCEEDED: 'exceeded_max_stake',
    EACHWAY_DIVISOR_EXPIRED: 'ew_expired',
    EACHWAY_PLACES_EXPIRED: 'ew_expired',
    EVENT_STARTED: 'event_started',
    SELECTION_SUSPENDED_SINGLE: 'sel_suspended_single',
    SELECTION_SUSPENDED_MULTIBET: 'sel_suspended_multi',
    SELECTION_SUSPENDED_MTS: 'sel_suspended_multi',
    MAX_PAYOUT_EXCEEDED: 'max_payout_exceeded',
    MINIMUM_ODDS_NOT_REACHED: 'min_odds_not_reached',
};

/**
 * @param {String} key error id
 * @returns {String} i18n betslip bet error key
 */
export const getI18nBetErrorKey = key => BET_ERRORS[key] || BET_ERRORS['DEFAULT_FALLBACK'];

const popupOnErrorCodes = [
    'CHARGE_FAILED',
    'INSUFFICIENT_FUNDS',
    'PLACEMENT_FAILED',
    'PLAYER_HAS_PENDING_ACTION',
    'PRICE_CHANGED',
    'SELECTION_SUSPENDED_MULTIBET',
    'SELECTION_SUSPENDED_SINGLE',
    'STORE_PENDING_FAILED',
    'UNKNOWN_ERROR',
    'UNAUTHORIZED',
    'REACHED_DAILY_WAGER_LIMIT',
    'REACHED_WEEKLY_WAGER_LIMIT',
    'REACHED_MONTHLY_WAGER_LIMIT',
    'REACHED_DAILY_LOSS_LIMIT',
    'REACHED_WEEKLY_LOSS_LIMIT',
    'REACHED_MONTHLY_LOSS_LIMIT',
    'CUSTOMER_EXCEEDED_SPAIN_INPLAY_WAGERING_LIMIT',
    'MTS_ODDS_TOO_HIGH',
    'GEO_EVENT_ERROR',
    'TIME_LIMIT_REACHED',
    'ERROR_CODE_PLAYER_NOT_AUTHENTICATED_DUE_TO_LUGAS_PARALLEL_PLAYING',
    'ERROR_CODE_PLAYER_NOT_AUTHENTICATED_DUE_TO_LUGAS_COOLING_PERIOD',
    'ERROR_CODE_PLAYER_NOT_AUTHENTICATED_DUE_TO_LUGAS_TECHNICAL_ISSUE',
];

export const getPopupErrors = fullListOfErrors =>
    fullListOfErrors.filter(
        error => popupOnErrorCodes.includes(error.id)
    );
