import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    placeBet,
} from '../thunks/placeBet';

import {
    closeModal,
    triggerMaxSelectionsModal,
    setFullScreenOff,
} from './uiSlice';

const initialState = null;

const betReceipt = createSlice({
    name: 'betslip/betReceipt',
    initialState,
    reducers: {
        goBackToBetslip: () => initialState,
    },
    extraReducers: {
        [placeBet.fulfilled]: (state, { payload }) => {
            return {
                ...payload,
                placedFrom: payload.pinnedTab,
            };
        },
        [closeModal]: () => initialState,
        [setFullScreenOff]: () => initialState,
        [triggerMaxSelectionsModal]: () => {
            return {
                errno: 'BETSLIP_LIMIT',
            };
        },
    },
});

export const {
    goBackToBetslip,
} = betReceipt.actions;

export default betReceipt.reducer;
