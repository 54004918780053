import queryString from 'query-string';

/**
 * HTTP success code
 * @type {Number}
 */
export const HTTP_SUCCESS = 200;

/**
 * HTTP Bad Request code
 * @type {Number}
 */
export const HTTP_BAD_REQUEST = 400;

/**
 * Default objects for the request
 * @type {Object}
 * @property {String} method - the request method, e.g., get, post, put, head.
 * @property {(Headers|String)} headers - any headers you want to add to your request, contained within a Headers object or an object literal
 * @property {String} mode - the mode you want to use for the request, e.g., cors, no-cors, or same-origin.
 * @property {String} credentials - the request credentials you want to use for the request: omit, same-origin, or include.
 * To automatically send cookies for the current domain, this option must be provided.
 * @property {String} cache - the cache mode you want to use for the request: default, no-store, reload, no-cache, force-cache, or only-if-cached.
 * @property {String} redirect - the redirect mode to use: follow (automatically follow redirects), error (abort with an error if a redirect occurs),
 * or manual (handle redirects manually).
 */
const defaultOptions = {
    method: 'get',
    mode: 'cors',
    credentials: 'include',
    cache: 'default',
    redirect: 'follow',
};

/**
 * Builds query string from a given Object
 * @param  {Object} data                Object to be converted into query string
 * @param  {Bool} prependQuestionMark   Whether to prepend question mark
 * @return {String}                     Query string
 */
export const buildQueryString = (data, prependQuestionMark = true) => {
    const qs = queryString.stringify(data);

    return `${prependQuestionMark ? '?' : ''}${qs}`;
};

/**
 * Makes a get http request
 * @param  {String} url the URL for the request
 * @param  {Object} options the options for the request
 * @return {Promise} the promise for the request
 */
export const get = (url, options = {}) => {
    const method = 'get';
    const headers = combineHeaders(options?.headers);
    const requestOptions = {
        ...defaultOptions,
        ...options,
        method,
        headers,
    };

    return fetch(url, requestOptions);
};

/**
 * Makes a post http request
 * @param  {String} url the URL for the request
 * @param  {*} data the URL for the request
 * @param  {Object} options the options for the request
 * @return {Promise} the promise for the request
 */
export const post = (url, data = {}, options = {}) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        if (typeof data[key] === 'undefined') {
            return;
        }

        if (typeof data[key] !== 'string') {
            formData.append(key, JSON.stringify(data[key]));
        } else {
            formData.append(key, data[key]);
        }
    });

    const method = 'post';
    const body = formData;
    const headers = combineHeaders(options?.headers);
    const requestOptions = {
        ...defaultOptions,
        ...options,
        method,
        body,
        headers,
    };

    return fetch(url, requestOptions);
};

/**
 * Combine all request headers
 * @param {Object} headers Request headers
 * @returns {Object} Request headers
 */
const combineHeaders = (headers) => {
    return {
        ...defaultOptions.headers,
        ...headers,
    };
};
