import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

/**
 * A function to fetch inplay eventData and scoreboardData
 * @param  { Number | String } eventId inplay [event.id]
 * @return { Object }
 */
export const inplayEventData = createAsyncThunk('inplayEventData', async (eventId) => {
    const [ eventData, scoreboardData ] = await Promise.allSettled([
        get(buildSpectateUrl(`/inplay-req/event/${eventId}`)).then(res => res.json()),
        get(buildSpectateUrl(`/inplay-req/getScoreboard/${eventId}`)).then(res => res.json()),
    ]);

    const {
        sport,
        category,
        tournament,
        event,
        markets,
        markets_events,
        uiMarkets,
        selections,
        scorecentre,
    } = eventData.value;

    return {
        sport,
        category,
        tournament,
        event,
        markets,
        markets_events,
        uiMarkets,
        selections,
        scoreboard: scoreboardData.value,
        scorecentre,
    };
});
