export default async () => {
    if(!global.fetch) {
        const wgfetch = await import(
            /* webpackChunkName: "polyfill_whatwg-fetch" */
            'whatwg-fetch'
        );
        Object.entries(wgfetch).forEach(([key, value]) => {
            global[key] = value;
        });
    }
};
