import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    clearSelections,
} from './userSelectionsSlice';

const initialState = [];

const availableBetTypes = createSlice({
    name: 'betslip/availableBetTypes',
    initialState,
    extraReducers: {
        [refreshBetslip.fulfilled]: (state, action) => {
            return action.payload.available_bet_types;
        },
        [clearSelections]: () => initialState,
    },
});

export default availableBetTypes.reducer;
