import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { inplayScoreboardUpdate } from '~SpectateStore/actions/inplayScoreboardUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const eventsAdapter = createEntityAdapter();

const eventsSlice = createSlice({
    name: 'events',
    initialState: eventsAdapter.getInitialState(),
    reducers: {
        addEvent: eventsAdapter.addOne,
        removeEvent: eventsAdapter.removeOne,
        updateSelectedMarketGroup: (state, { payload: { eventId, slug }}) => {
            state.entities[eventId]['selected_tab'] = slug;
        },
    },
    extraReducers: {
        [eventDataCleanUp] : (state, { payload : eventId }) => {
            eventsAdapter.removeOne(state, eventId);
        },
        [inplayEventData.fulfilled] : (state, { payload : { event } }) => {
            return eventsAdapter.addOne(state, event);
        },
        [inplayScoreboardUpdate] : (state, { payload : { event } }) => {
            if (event?.id) {
                return eventsAdapter.upsertOne(state, event);
            }

            return state;
        },
    },
});

export const { addEvent, removeEvent, updateSelectedMarketGroup } = eventsSlice.actions;
export default eventsSlice.reducer;
