import React from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SpectateStoreProvider } from './SpectateStore/SpectateStoreProvider';
import { Router } from 'react-router-dom';
import { history } from '~spa/Router/memoryHistory';
import SpectateClient from './SpectateClient';

const SpectateApp = ({init}) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <SpectateStoreProvider>
            <QueryClientProvider client={queryClient}>
                <Router history={history}>
                    <SpectateClient init={init} />
                </Router>
            </QueryClientProvider>
        </SpectateStoreProvider>
    );
};

SpectateApp.propTypes = {
    init: PropTypes.object.isRequired,
};

export {
    SpectateApp,
};


