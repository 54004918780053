import { createMemoryHistory } from 'history';

const history = createMemoryHistory({
    initialEntries: [{
        state: {},
    }],
});

const pushHistory = (context) => history.push(
    context.systemUrlPath,
    {
        contextId: context.contextId,
        context: {...context},
    }
);

export {
    history,
    pushHistory,
};
