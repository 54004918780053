import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    isSelectionSuspended,
} from '~Betslip/utils/selectionChecks';

export const selectSelectionDetailsById = (state, selectionId) => {
    if (!state.betslip.selectionDetails[selectionId]) {
        return null;
    }
    const currentSelection = {...state.betslip.selectionDetails[selectionId]};
    const suspended = isSelectionSuspended(currentSelection);

    Object.assign(currentSelection, { suspended });

    return currentSelection;
};

export const selectSelectionDetails = (state) => state.betslip.selectionDetails;

export const selectCountSelectionDetails = state => Object.keys(state.betslip.selectionDetails).length;

export const selectCountSuspendedSelections = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) => {
        return Object.values(selections).reduce((total, selection) => {
            const suspended = isSelectionSuspended(selection);

            if (suspended) {
                return total + 1;
            }

            return total;
        }, 0);
    }
);

export const selectAnySelectionSuspended = createSelector(
    [
        selectCountSuspendedSelections,
    ],
    (suspendedSelectionCount) => suspendedSelectionCount > 0
);

export const selectHasInplay = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) =>
        Object.values(selections).some(selection => selection.is_inplay),
);

export const selectAllSelectionsAreSuspended = createSelector(
    [
        selectCountSelectionDetails,
        selectCountSuspendedSelections,
    ],
    (allSelectionsCount, suspendedCount) => (allSelectionsCount === suspendedCount),
);

export const selectSuspendedSelections = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) => {
        return Object.keys(selections).reduce((acc, selectionId) => {
            const selection = selections[selectionId];
            const isSuspended = isSelectionSuspended(selection);

            return isSuspended
                ? {
                    ...acc,
                    [selectionId]: selection,
                }
                : acc;
        }, {});
    },
);

export const selectHasOddsBoost = createSelector(
    [selectSelectionDetailsById],
    (selection) => {
        if (!selection) {
            return false;
        }

        return selection.is_odds_boost;
    }
);
