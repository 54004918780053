import { inplayHomepageUrl } from '~spa/Utils/LocalizedUrls';
import i18n from '~shared/utils/i18n';

/**
 * Collection of functions to generate SEO URLs.
 * @see https://dedsert.atlassian.net/wiki/spaces/DEV/pages/819069469/Multi+lingual+urls
 */

export const SEO_URL_SUFFIX_SPORT = 's';
export const SEO_URL_SUFFIX_SPORT_ANTEPOST = 'ap';
export const SEO_URL_SUFFIX_SPORT_FEATURED = 'sf';
export const SEO_URL_SUFFIX_SPORT_POPULAR_BETS = 'sp';
export const SEO_URL_SUFFIX_SPORT_SPECIALS = 'ss';
export const SEO_URL_SUFFIX_RACING_NEXT = 'rn';
export const SEO_URL_SUFFIX_RACING_MEETINGS = 'rm';
export const SEO_URL_SUFFIX_RACING_BETFINDER = 'bfi';
export const SEO_URL_SUFFIX_SPORT_VIRTUALS = 'sv';
export const SEO_URL_SUFFIX_TOURNAMENT = 't';
export const SEO_URL_SUFFIX_TOURNAMENT_OUTRIGHTS = 'to';
export const SEO_URL_SUFFIX_TOURNAMENT_OUTRIGHT = 'o';
export const SEO_URL_SUFFIX_TOURNAMENT_DATE = 'toda';
export const SEO_URL_SUFFIX_CATEGORY = 'cat';
export const SEO_URL_SUFFIX_EVENT = 'e';
export const SEO_URL_SUFFIX_COMPETITION_SPORT = 'coms';
export const SEO_URL_SUFFIX_COMPETITION_CATEGORY = 'comcat';
export const SEO_URL_SUFFIX_INPLAY_HOMEPAGE = 'ip';
export const SEO_URL_SUFFIX_INPLAY_SCHEDULED = 'ipsche';
export const SEO_URL_SUFFIX_INPLAY_SCHEDULED_DATE = 'ipscheda';
export const SEO_URL_SUFFIX_INPLAY_VIRTUAL = 'ipvirt';
export const SEO_URL_SUFFIX_INPLAY_SPORT = 'ips';
export const SEO_URL_SUFFIX_INPLAY_EVENT = 'ipe';
export const SEO_URL_SUFFIX_BETFEED = 'bf';
export const SEO_URL_SUFFIX_RECENTLY_WON = 'mfywon';
export const SEO_URL_SUFFIX_RECENTLY_BACKED = 'mfyback';
export const SEO_URL_SUFFIX_FOR_TODAY = 'mfytoday';
export const SEO_URL_SUFFIX_BET_HISTORY = 'b';
export const SEO_URL_SUFFIX_OPEN_BETS = 'bo';
export const SEO_URL_SUFFIX_SETTLED_BETS = 'bs';
export const SEO_URL_SUFFIX_OLDER_BETS = 'boh';
export const SEO_URL_CONTEXT_SEPARATOR = '/';
/**
 * Only used for e-*, t-* and ipe-* - see AT-9010 - no plan to extend to other urls for now
 */
export const SEO_URL_ALT_CONTEXT_SEPARATOR = '-';
export const URL_SLUGS = {
    COMPETITIONS: 'competitions',
    POPULAR_BETS: 'popular-bets',
    VIRTUALS: 'virtuals',
    ODDS_BOOST: 'odds-boost',
    NEXT_RACES: 'next-races',
    FEATURED: 'featured',
    MEETINGS: 'meetings',
    BETFINDER: 'betfinder',
    ANTE_POST: 'ante-post',
};

/**
 * Creates a SEO URL by combining the provided URL path with
 * the context label and the optional resource ID.
 * Returns an absolute path.
 *
 * @param {String} path The URL path
 * @param {String} contextLabel The required context label
 * @param {Number} id The resource ID
 * @param {String} contextSeparator the separator of resource ID after path
 * @returns {String} The SEO URL
 */
function buildSeoUrl(path, contextLabel, id = null, contextSeparator = SEO_URL_CONTEXT_SEPARATOR) {
    let urlPath = path;
    if (typeof urlPath !== 'string' || !urlPath.length) {
        // We need to fallback to "goto" segment if we received invalid path
        // for example: undefined, null, true, false, etc
        urlPath = 'goto';
    }

    if (id !== null && urlPath.includes(`${contextSeparator}${contextLabel}-${id}`)) {
        return urlPath;
    }

    const seoUrl = `/${urlPath.replace(/^\/|\/$/g, '')}${contextSeparator}${contextLabel}`;

    // NOTE: The returned URL must not end with '/'.
    // The '/' is appended by rewriteUrl() (shared/utils/uriAliases.js).
    if (id !== null) {
        return `${seoUrl}-${id}/`;
    }

    return `${seoUrl}/`;
}

/**
 * Get a base url for an event - generally <sport>/<category>/<tournament>/<event>.
 * Checks for the presence of i18n slugs and uses these in preference to the non-translated slugs.
 * @param {Object} eventObject the event object containing required slugs
 * @returns {String} the base event url
 */
export const getBaseEventUrl = (eventObject) => {
    const {
        sport_slug_i18n = '',
        sport_slug = '',
        category_slug_i18n = '',
        category_slug = '',
        tournament_slug_i18n = '',
        tournament_slug = '',
        event_slug_i18n = '',
        event_slug = '',
        slug = '',
    } = eventObject;

    const sport = sport_slug_i18n || sport_slug;
    const category = category_slug_i18n || category_slug;
    const tournament = tournament_slug_i18n || tournament_slug;
    const event = event_slug_i18n || event_slug || slug;

    return `${sport}/${category}/${tournament}/${event}`;
};

/**
 * slugify the entity by removing special characters and replacing spaces with hypen
 *
 * @param {String} entity The URL path
 * @returns {String} The Slug
 */
export const slugifyUrlEntities = (entity) => {
    let filteredEntity = entity.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');

    return filteredEntity.replace(/\s+/g, '-').toLowerCase();
};

// Sport URLs
export const seoUrlSport = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_SPORT, id);

export const seoUrlSportAntepost = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_SPORT_ANTEPOST, id);

export const seoUrlSportFeatured = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_SPORT_FEATURED, id);

export const seoUrlSportPopularBets = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_SPORT_POPULAR_BETS, id);

export const seoUrlSportOddsBoost = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_SPORT_SPECIALS, id);

export const seoUrlSportVirtuals = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_SPORT_VIRTUALS, id);

// Racing
export const seoUrlRacingNextRaces = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_RACING_NEXT, id);

export const seoUrlRacing = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_RACING_MEETINGS, id);

export const seoUrlRacingBetfinder = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_RACING_BETFINDER, id);

// Tournament URLs
export const seoUrlTournament = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_TOURNAMENT, id, SEO_URL_ALT_CONTEXT_SEPARATOR);

export const seoUrlTournamentOutrights = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_TOURNAMENT_OUTRIGHTS, id, SEO_URL_ALT_CONTEXT_SEPARATOR);

export const seoUrlTournamentOutright = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_TOURNAMENT_OUTRIGHT, id, SEO_URL_ALT_CONTEXT_SEPARATOR);

export const seoUrlTournamentDate = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_TOURNAMENT_DATE, id, SEO_URL_ALT_CONTEXT_SEPARATOR);

// Category URLs
export const seoUrlCategory = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_CATEGORY, id);

// Event URLs
export const seoUrlEvent = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_EVENT, id, SEO_URL_ALT_CONTEXT_SEPARATOR);

// Competitions URLs
export const seoUrlCompetitionSport = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_COMPETITION_SPORT, id);

export const seoUrlCompetitionCategory = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_COMPETITION_CATEGORY, id);

// Inplay URLs
export const seoUrlInplay = () => buildSeoUrl(inplayHomepageUrl(), SEO_URL_SUFFIX_INPLAY_HOMEPAGE);

export const seoUrlInplayScheduled = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_INPLAY_SCHEDULED);

export const seoUrlInplayScheduledDate = (path, date) => buildSeoUrl(path, SEO_URL_SUFFIX_INPLAY_SCHEDULED_DATE, date);

export const seoUrlInplayVirtual = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_INPLAY_VIRTUAL);

export const seoUrlInplaySport = (sportSlug, id) => buildSeoUrl(`${inplayHomepageUrl()}/${sportSlug}`, SEO_URL_SUFFIX_INPLAY_SPORT, id);

export const seoUrlInplayEvent = (path, id) => buildSeoUrl(path, SEO_URL_SUFFIX_INPLAY_EVENT, id, SEO_URL_ALT_CONTEXT_SEPARATOR);

// BetFeed
export const seoUrlBetfeed = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_BETFEED);

// Made For You
export const seoUrlRecentlyWon = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_RECENTLY_WON);

export const seoUrlRecentlyBacked = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_RECENTLY_BACKED);

export const seoUrlForToday = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_FOR_TODAY);

// Bet History
export const seoUrlBetHistory = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_BET_HISTORY);

export const seoUrlOpenBets = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_OPEN_BETS);

export const seoUrlSettledBets = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_SETTLED_BETS);

export const seoUrlOlderBets = (path) => buildSeoUrl(path, SEO_URL_SUFFIX_OLDER_BETS);

export const isSportPageContext = (context) => {
    const sportPageContext = [
        SEO_URL_SUFFIX_SPORT,
        SEO_URL_SUFFIX_SPORT_POPULAR_BETS,
        SEO_URL_SUFFIX_SPORT_SPECIALS,
        SEO_URL_SUFFIX_SPORT_VIRTUALS,
        SEO_URL_SUFFIX_COMPETITION_SPORT,
    ];

    return sportPageContext.includes(context);
};

export const getSportsPageSeoUrl = (sportSlug, pageSlug, sportId) => {
    const sportPath = i18n.t(`url.${sportSlug}`);
    const localizedSlug = i18n.t(`url.${pageSlug}`);
    switch (pageSlug) {
        case URL_SLUGS.COMPETITIONS:
            return seoUrlCompetitionSport(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.POPULAR_BETS:
            return seoUrlSportPopularBets(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.ODDS_BOOST:
            return seoUrlSportOddsBoost(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.VIRTUALS:
            return seoUrlSportVirtuals(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.ANTE_POST:
            return seoUrlSportAntepost(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.BETFINDER:
            return seoUrlRacingBetfinder(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.MEETINGS:
            return seoUrlRacing(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.NEXT_RACES:
            return seoUrlRacingNextRaces(`${sportPath}/${localizedSlug}`, sportId);
        case URL_SLUGS.FEATURED:
            return seoUrlSportFeatured(`${sportPath}/${localizedSlug}`, sportId);
        default:
            return seoUrlSport(sportPath, sportId);
    }
};

export const getSportsPageBreadcrumb = (context, sportSlug, sportId) => {
    let sportPageName;
    let sportPageUrl;

    switch(context) {
        case SEO_URL_SUFFIX_COMPETITION_SPORT:
            sportPageName = i18n.t('sports.competitions');
            sportPageUrl = seoUrlCompetitionSport(`${sportSlug}/${i18n.t('url.competitions')}`, sportId);
            break;
        case SEO_URL_SUFFIX_SPORT_POPULAR_BETS:
            sportPageName = i18n.t('sports.popular_bets');
            sportPageUrl = seoUrlSportPopularBets(`${sportSlug}/${i18n.t('url.popular-bets')}`, sportId);
            break;
        case SEO_URL_SUFFIX_SPORT_VIRTUALS:
            sportPageName = i18n.t('virtuals.virtuals');
            sportPageUrl = seoUrlSportVirtuals(`${sportSlug}/${i18n.t('url.virtuals')}`, sportId);
            break;
        case SEO_URL_SUFFIX_SPORT_SPECIALS:
            sportPageName = i18n.t('odds_boost.odds_boost');
            sportPageUrl = seoUrlSportOddsBoost(`${sportSlug}/${i18n.t('url.odds-boost')}`, sportId);
            break;
        case SEO_URL_SUFFIX_SPORT_FEATURED:
            sportPageName = i18n.t('racing.featured');
            sportPageUrl = seoUrlSportFeatured(`${sportSlug}/${i18n.t('url.featured')}`, sportId);
            break;
        case SEO_URL_SUFFIX_RACING_NEXT:
            sportPageName = i18n.t('racing.next_races_heading');
            sportPageUrl = seoUrlRacingNextRaces(`${sportSlug}/${i18n.t('url.next-races')}`, sportId);
            break;
        case SEO_URL_SUFFIX_RACING_MEETINGS:
            sportPageName = i18n.t('racing.meetings');
            sportPageUrl = seoUrlRacing(`${sportSlug}/${i18n.t('url.meetings')}`, sportId);
            break;
        case SEO_URL_SUFFIX_RACING_BETFINDER:
            sportPageName = i18n.t('bet_finder.bet_finder');
            sportPageUrl = seoUrlRacingBetfinder(`${sportSlug}/${i18n.t('url.betfinder')}`, sportId);
            break;
        case SEO_URL_SUFFIX_SPORT_ANTEPOST:
            sportPageName = i18n.t('racing.ante-post');
            sportPageUrl = seoUrlSportAntepost(`${sportSlug}/${i18n.t('url.ante-post')}`, sportId);
            break;
        default:
            break;
    }

    return {
        sportPageName,
        sportPageUrl,
    };
};
