import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const uiMarketsAdapter = createEntityAdapter();

const uiMarketsSlice = createSlice({
    name: 'uiMarkets',
    initialState: uiMarketsAdapter.getInitialState(),
    reducers: {
        addUiMarkets: uiMarketsAdapter.addMany,
        removeUiMarkets: uiMarketsAdapter.removeMany,
    },
    extraReducers: {
        [eventDataCleanUp] : (state, { payload : eventId }) => {
            Object.values(state.entities).forEach(uiMarket => {
                if(Number(uiMarket.event_id) === Number(eventId)) {
                    uiMarketsAdapter.removeOne(state, uiMarket.id);
                }
            });
        },
        [inplayEventData.fulfilled] : (state, { payload : { uiMarkets } }) => {
            uiMarketsAdapter.addMany(state, uiMarkets);
        },
    },
});

export const { addUiMarkets, removeUiMarkets } = uiMarketsSlice.actions;
export default uiMarketsSlice.reducer;
