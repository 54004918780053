import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';

export const sportsAdapter = createEntityAdapter();

const sports = createSlice({
    name: 'sports',
    initialState: sportsAdapter.getInitialState(),
    reducers: {
        addSport: sportsAdapter.addOne,
        removeSport: sportsAdapter.removeOne,
    },
    extraReducers: {
        [inplayEventData.fulfilled] : (state, { payload : { sport } }) => {
            return sportsAdapter.addOne(state, sport);
        },
    },
});

export const { addSport, removeSport } = sports.actions;

export default sports.reducer;
