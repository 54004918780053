import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    clearSelections,
} from './userSelectionsSlice';

const initialState = [];

const getUniqueSelectionIds = (contingencies) => [
    ...new Set(
        contingencies
            .reduce(
                (acc, curr) => [...acc, ...curr],
                []
            )
            .map(String)
    ),
];

const contingencies = createSlice({
    name: 'betslip/contingencies',
    initialState,
    extraReducers: {
        [refreshBetslip.fulfilled]: (state, action) => {
            return getUniqueSelectionIds(action.payload.contingencies);
        },
        [clearSelections]: () => initialState,
    },
});

export default contingencies.reducer;
