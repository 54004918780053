const BETSLIP_STORAGE_KEY = 'betslipState';
const USER_STORAGE_KEY = 'userState';
const CLIENT_STATE_STORAGE_KEY = 'clientState';

const loadState = (storageKey, isPersistent = false) => {
    const storage = isPersistent ? localStorage : sessionStorage;

    try {
        const state = storage.getItem(storageKey);

        if (state === null) {
            return undefined;
        }

        return JSON.parse(state);
    } catch (error) {
        return undefined;
    }
};

const saveState = (storageKey, state, isPersistent = false) => {
    const storage = isPersistent ? localStorage : sessionStorage;

    try {
        const serializedState = JSON.stringify(state);
        storage.setItem(
            storageKey,
            serializedState
        );
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Warning: failed to save state item in ${isPersistent ? 'local' : 'session'}Storage`);
    }
};

const loadNonPersistentState = (storageKey) => loadState(storageKey);
const loadPersistentState = (storageKey) => loadState(storageKey, true);

const loadInitialState = (storageKey) => {
    const nonPersistentState = loadNonPersistentState(storageKey);
    const persistentState = loadPersistentState(storageKey);

    if (nonPersistentState || persistentState) {
        return {
            ...nonPersistentState,
            ...persistentState,
        };
    }

    return undefined;
};

const saveNonPersistentState = (storageKey, state) => saveState(storageKey, state);
const savePersistentState = (storageKey, state) => saveState(storageKey, state, true);

export const loadBetslipInitialState = () => loadInitialState(BETSLIP_STORAGE_KEY);

export const saveBetslipNonPersistentState = (state) => saveNonPersistentState(BETSLIP_STORAGE_KEY, state);
export const saveBetslipPersistentState = (state) => savePersistentState(BETSLIP_STORAGE_KEY, state);

export const loadUserInitialState = () => loadInitialState(USER_STORAGE_KEY);
export const saveUserNonPersistentState = (state) => saveNonPersistentState(USER_STORAGE_KEY, state);

export const loadClientStateInitialState = () => loadInitialState(CLIENT_STATE_STORAGE_KEY);
export const saveClientStateNonPersistentState = (state) => saveNonPersistentState(CLIENT_STATE_STORAGE_KEY, state);
