import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';
import {
    selectUserSelectionIds,
    selectCountUserSelections,
} from '~Betslip/selectors/userSelectionsSelectors';

import {
    selectBetReceiptExists,
    selectBetReceiptIds,
    selectCountReceiptSelections,
} from '~Betslip/selectors/betReceiptSelectors';

import {
    selectRecommendationGlobalEnabled,
    selectRecommendationBetPlacementEnabled,
    selectRecommendationBetReceiptEnabled,
} from '~SpectateStore/selectors/clientStateSelectors';


export const getRecommendations = createAsyncThunk(
    'betslip/getRecommendations',
    async (_, { getState, rejectWithValue }) => {
        const betslipSelections = selectUserSelectionIds(getState());
        const isBetReceipt = selectBetReceiptExists(getState());
        const betReceiptSelections = selectBetReceiptIds(getState());

        const selections = isBetReceipt ? betReceiptSelections : betslipSelections;

        const url = buildSpectateUrl(`/recommendations_requests/getRecommendationsBetslip?selections=${selections}`);

        const response = await get(url);
        if (!response.ok) {
            rejectWithValue([]);
        }

        return response.json();
    },
    {
        condition: (_, { getState }) => {
            const betslipCount = selectCountUserSelections(getState());
            const receiptCount = selectCountReceiptSelections(getState());

            const shouldGetRecommendations = selectRecommendationGlobalEnabled(getState());
            const shouldGetBetslipRecommendations = selectRecommendationBetPlacementEnabled(getState()) && betslipCount > 0 && receiptCount < 1;
            const shouldGetBetReceiptRecommendations = selectRecommendationBetReceiptEnabled(getState()) && receiptCount > 0;

            return Boolean(shouldGetRecommendations) && (Boolean(shouldGetBetslipRecommendations) || Boolean(shouldGetBetReceiptRecommendations));
        },
    }
);
