import './public-path';

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { initSpectate } from './Utils/Bootstrap';
import { getSpaContainerId } from './Utils/Spectate';
import {
    clientLoaded,
    getClientSettings,
    ucDataStoreReady,
    ucSdkReady,
    setUcLib,
} from './Utils/WrapperIntegration/Ucf';
import {
    intialiseDataLayer,
} from '~shared/utils/google-tag-manager';
import { SpectateApp } from './SpectateApp';


const initialize = (init) => {
    ReactDOM.render(
        <StrictMode>
            <SpectateApp init={init} />
        </StrictMode>,
        document.getElementById(getSpaContainerId())
    );
};

intialiseDataLayer();

ucSdkReady()
    .then(
        (ucLib) => {
            setUcLib(ucLib);
            clientLoaded(initialize);
        }
    )
    .then(ucDataStoreReady)
    .then(
        () => initSpectate(getClientSettings())
    )
    .catch(console.error); //eslint-disable-line
