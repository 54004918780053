import {
    getCurrencyIso,
    getOddsFormat,
} from '~shared/utils/user';

export const actionTypes = ['add', 'remove'];

/**
 * Takes in element & value to setAttribute
 * @param {object} curEl current element
 * @param {string} value  attribute string
 * @returns {undefined}
 */
export function setGtmDataElement(curEl, value) {
    curEl.setAttribute('data-spectate-product', value);
}


/**
 * initialise the GTM dataLayer
 * @returns {undefined}
 */
export function intialiseDataLayer() {
    window.dataLayer = window.dataLayer || [];
}

/**
 * Takes in a value to be stored in the data layer
 *
 * @param {object} value pass in value you want pushed to the data layer
 * @returns {object} the data that needs to be pushed
 */
export function pushToDataLayer(value) {
    window.dataLayer.push(value);
}

/**
 * Add purchase data to the eCommerce data layer for GTM
 *
 * @param {object} bet The maximum number of attempts
 * @param {array} prices The minimum interval time
 * @param {object} selectionsDetails The maximum interval time
 * @param {string} castmarketId The id used for forecast/tricast bets
 * @returns {object} The GTM data
 */
   export function eCommerceGTMPurchase(bet, prices, selectionsDetails, castmarketId) {

    if(bet.success) {

        // Populate action
        const actionField = {
            'id': bet.id,
            'revenue': bet.total_stake,
            'coupon': bet.free_bet_id,
        };

        // Populate selections as product
        const products = bet.selection_ids.map(function callback(selectionId) {
        let details;
        let priceSP = false;
        const isScorecast = bet.bet_type.toLowerCase() === 'scorecast';
        const isCastMarket = bet.bet_type.toLowerCase() === 'forecast' || bet.bet_type.toLowerCase() === 'tricast';
        const zero = '0';

        if (isScorecast) {
            const scorecastSelectionId = bet['selection_ids'].join('_');
            details = selectionsDetails[scorecastSelectionId];
        } else if (isCastMarket) {
            details = selectionsDetails[castmarketId];
        } else {
            details = selectionsDetails[selectionId];
            priceSP = prices[selectionId].decimal === zero;
        }

            return {
                'name': (isCastMarket) ? details.selection_names.join(', ') : details.selection_name,
                'id': selectionId,
                'category': details.sport,
                'brand': details.event_name,
                'variant': details.market_name,
                'dimension1': bet.each_way ? 'Yes' : 'No',
                'dimension2': getOddsFormat(),
                'dimension3': details.is_inplay ? 'In-Play' : 'Pre-Match',
                'dimension4': bet.bet_type,
                'dimension5': details.event_id,
                'dimension6': priceSP ? 'SP' : details.fraction_price,
                'dimension7': details.market_id,
            };

        });

        pushToDataLayer({
            'event': 'transaction',
            'ecommerce': {
                'currencyCode': getCurrencyIso(),
                'purchase': {
                    'actionField': actionField,
                    'products': products,
                },
                //
                'add': undefined,
                'remove': undefined,
            },
        });
    }
}

/**
 * Add purchase data to the eCommerce data layer for GTM
 *
 * @param {object} selectionDetails The details of selections
 * @param {Integer} selectionId The selection id
 * @param {String} type If its an Add or Remove
 * @param {String} event type of push event
 * @returns {object} The GTM data
 */
   export function eCommerceGTMSelection(selectionDetails, selectionId, type, event) {
    const two = 2;
    if (selectionDetails) {

        // remove unneeded data from being pushed to datalayer
        const actionType = actionTypes.filter(t => (t !== type));

        // Populate selections as product
        const product = {
            'name': selectionDetails.selection_name,
            'id': selectionId.toString(),
            'category': selectionDetails.sport,
            'brand': selectionDetails.event_name,
            'variant': selectionDetails.market_name,
            'dimension1': selectionDetails.each_way_allowed ? 'Yes' : 'No',
            'dimension2': selectionDetails.event_id,
            'dimension3': selectionDetails.decimal_price ? parseFloat(selectionDetails.decimal_price).toFixed(two) : 'SP',
        };

        pushToDataLayer({
            event,
            'ecommerce': {
                [type]: {
                    'products': product,
                },
                [actionType]: undefined,
                'purchase': undefined,
            },
        });
    }
}

/**
 *
 * @param {string} gtmAttribute The data-spectate-product attribute
 * @param {string} gtmEnd The end tag
 * @returns {string} The GTM tag
 */
export function updateGTMtag(gtmAttribute, gtmEnd) {
    const gtmBase = gtmAttribute.split(';');
    gtmBase.pop();

    return gtmBase.join(';') + gtmEnd;
}
