import { isLegacy } from '../currencyFormatterLegacy';

export default async () => {
    if(isLegacy) {
        global.legacyCurrencyFormatter = (
            await import(
                /* webpackChunkName: "polyfill_currency-formatter" */
                'currency-formatter'
            )
        ).default;
    }
};
