import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectShowModal,
    selectUiMode,
    selectDisplayFullslip,
} from '~Betslip/selectors/uiSelectors';

export const selectBets = ({ betslip: { betReceipt }}) => {
    return betReceipt ? betReceipt.bets : [];
};

export const selectBetReceiptIds = createSelector(
    selectBets,
    bets => {
        const allIds = bets.reduce((acc, bet) => {
            return [
                ...acc,
                ...bet.selection_ids,
            ];
        }, []);

        return [...new Set(allIds)];
    }
);

export const selectCountReceiptSelections = createSelector(
    selectBetReceiptIds,
    ids => ids.length
);

export const selectBetsErrors = ({ betslip: { betReceipt }}) => {
    return betReceipt ? betReceipt.errors : [];
};

export const selectErrno = ({ betslip: { betReceipt }}) => {
    return betReceipt?.errno || 0;
};

export const selectBetTotalStake = ({ betslip: { betReceipt }}) => {
    return betReceipt ? betReceipt.total_stake : 0;
};

export const selectBetTotalReturns = ({ betslip: { betReceipt }}) => {
    return betReceipt && betReceipt.total_potential_returns ? betReceipt.total_potential_returns : 'N/A';
};

export const selectTabPlacedFrom = ({ betslip: { betReceipt }}) => {
    return betReceipt ? betReceipt.placedFrom : '';
};

export const selectBetReceiptExists = state => state.betslip.betReceipt !== null;

export const selectShowBetReceipt = createSelector(
    [
        selectBetReceiptExists,
        selectShowModal,
    ],
    (betReceiptExists, modalIsShown) => {
        return betReceiptExists && !modalIsShown;
    },
);

export const selectShowBabyBetReceipt = createSelector(
    [
        selectBetReceiptExists,
        selectShowModal,
        selectUiMode,
        selectDisplayFullslip,
    ],
    (betReceiptExists, modalIsShown, uiMode, displayFullslip) => {
        return betReceiptExists && !modalIsShown && uiMode === 'baby' && !displayFullslip;
    },
);
