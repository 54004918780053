const run = async (method, ...args) => {
    const interactions = await import(
        /* webpackChunkName: "betslip-interactions" */
        '~Betslip/interface/interactionHandlers'
    );

    try {
        interactions[method](...args);
    } catch (error) {
        console.error(error); //eslint-disable-line
    }
};

export const addFcTcToBetslip = (...args) => run('addFcTcToBetslip', ...args);
export const addSelectionsToBetslip = (...args) => run('addSelectionsToBetslip', ...args);
export const addSelectionToBetslip = (...args) => run('addSelectionToBetslip', ...args);
export const addToBetslipFromDeeplink = (...args) => run('addToBetslipFromDeeplink', ...args);
export const clearBetslip = (...args) => run('clearBetslip', ...args);
export const clearBetslipFreebets = (...args) => run('clearBetslipFreebets', ...args);
export const clearBetslipSelections = (...args) => run('clearBetslipSelections', ...args);
export const closeErrorModal = (...args) => run('closeErrorModal', ...args);
export const disableBetting = (...args) => run('disableBetting', ...args);
export const enableBetting = (...args) => run('enableBetting', ...args);
export const exitFullScreenBetslip = (...args) => run('exitFullScreenBetslip', ...args);
export const fetchBetslipFreebets = (...args) => run('fetchBetslipFreebets', ...args);
export const initBetslip = (...args) => run('initBetslip', ...args);
export const openBetslipMyBetsTab = (...args) => run('openBetslipMyBetsTab', ...args);
export const removeSelectionFromBetslip = (...args) => run('removeSelectionFromBetslip', ...args);
export const setBetslipFullScreen = (...args) => run('setBetslipFullScreen', ...args);
export const updateBetslipOpenBetsCounter = (...args) => run('updateBetslipOpenBetsCounter', ...args);
